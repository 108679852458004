import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BadHistoryService, CommonService, CostEquipmentService, 
  EmployeeService, FileManagerService, ReasonLeaveWorkService, 
  RemarkEmployeeService } from '../shared';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  personnel;
  employee;
  costEquipmentList = [];
  badHistoryList = [];
  reasonLeaveWorkList = [];
  remarkList = [];
  recommender;
  urlProfile = '';
  fileInfoMap = new Map();

  constructor(
    private costEquipmentService: CostEquipmentService,
    private fileManagerService: FileManagerService,
    private badHistoryService: BadHistoryService,
    private reasonLeaveWorkService: ReasonLeaveWorkService,
    private remarkEmployeeService: RemarkEmployeeService,
    private employeeService: EmployeeService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.personnel = JSON.parse(localStorage.getItem('tisToken'));
    console.log(this.personnel);
    this.getEmployee(this.personnel.id);
  } 

  getEmployee(employeeId){
    this.spinner.show();
    this.costEquipmentService.getByEmployeeId(employeeId).subscribe(data => {
      console.log(data);
      if(data.length > 0){
        this.costEquipmentList = data;
      }
    }, error => {
      // this.addCostEquipmentList()
      console.error(error);
    });
    this.fileManagerService.getByEmployeeId(employeeId).subscribe(data => {
      console.log(data);
      data.forEach((val) => {
        this.fileInfoMap.set(val.id,val);
      });
    }, error => {
      console.error(error);
      //this.failDialog(error);
    });
    this.badHistoryService.getByEmployeeId(employeeId).subscribe(data => {
      console.log(data);
      this.badHistoryList = data;
    }, error => {
      console.error(error);
      //this.failDialog(error);
    });
    this.reasonLeaveWorkService.getByEmployeeId(employeeId).subscribe(data => {
      console.log(data);
      this.reasonLeaveWorkList = data;
    }, error => {
      console.error(error);
      //this.failDialog(error);
    });
    this.remarkEmployeeService.getRemarkByEmplyeeId(employeeId).subscribe(data =>{
      this.remarkList = data;
      try{
        this.remarkList.forEach(value => {
          //value['isDeleted'] = false;
        });
      }catch(ex){
        this.remarkList = [];
      } 
    }, error => {
      console.error(error);
      //this.failDialog(error);
    });
     
    this.employeeService.getEmployeeById(employeeId).subscribe(data => {
      if(data.recommender){
        this.recommender = data.recommender
      }
      this.employee = data;
      console.log(this.employee);
      if(this.employee.password !== this.personnel.password){
        localStorage.clear();
        localStorage.removeItem('tisToken');
        window.location.href = "/";
      }
      this.employee.date_of_birth = this.commonService.getDateThNotimeDDMMYYYY2(this.employee.date_of_birth);
      this.employee.expired_card = this.commonService.getDateThNotimeDDMMYYYY2(this.employee.expired_card);
      this.employee.start_work = this.commonService.getDateThNotimeDDMMYYYY2(this.employee.start_work);
      this.employee.leave_work = this.commonService.getDateThNotimeDDMMYYYY2(this.employee.leave_work);
      this.urlProfile = data.url;
      console.log(this.employee);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      console.error(error);
      //this.failDialog(error);
    });
  }

}
